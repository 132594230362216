var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{style:(_vm.$vuetify.breakpoint.name == 'lg'
          ? 'padding-top:170px !important'
          : _vm.$vuetify.breakpoint.name == 'md'
          ? 'padding-top:30px !important'
          : _vm.$vuetify.breakpoint.name == 'sm'
          ? 'padding-top:10px !important'
          : _vm.$vuetify.breakpoint.name == 'xs'
          ? 'padding-top:10px !important'
          : 'padding-top:170px !important'),attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }